import React from 'react'
import TopicListItem from './topic-list-item'
import { AuthUserContext } from '../session'

const TopicList = ({ topics, subtopics, urlBase }) => {
  const getSubItems = (topic, subtopics) => {
    const topicSlug = topic.slug
    return subtopics.filter(function(subtopic) {
      if (
        subtopic.topic &&
        subtopic.topic[0] &&
        subtopic.topic[0].slug === topicSlug
      ) {
        return subtopic
      }
    })
  }

  const renderItems = (topics, subtopics, authUser) => {
    return topics
      .filter(topic => topic.title)
      .map(function(topic, i) {
        const key = `topic-${i}`
        if (topic.title) {
          const subItems = getSubItems(topic, subtopics)

          return (
            <TopicListItem
              key={key}
              title={topic.title}
              subItems={subItems}
              authUser={authUser}
              index={i}
              urlBase={urlBase}
            />
          )
        } else {
          return null
        }
      })
  }

  return (
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser ? renderItems(topics, subtopics, authUser) : null
      }
    </AuthUserContext.Consumer>
  )
}

export default TopicList
