import React from 'react'
import { Wrap } from '../components/page/components'
import { graphql } from 'gatsby'
import TopicList from '../components/graphic-design/topic-list'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import SEO from '../components/SEO'
import AppHeader from '../components/global/app-header'
import AppBody from '../components/page/app-body'
import AppMenu from '../components/page/app-menu'
import { navigate } from 'gatsby'

const TeacherGuideBase = ({ data, authUser, location }) => {
  const { topics, subtopics } = data.contentfulCourse
  const { teacher: isTeacher, admin: isAdmin } = authUser.roles
  console.log(data)

  if (!isTeacher && !isAdmin) {
    navigate('/')
  }

  return (
    <>
      <Wrap>
        <SEO title="Teacher Guide" />
        <AppHeader
          title="Teacher Guide"
          authUser={authUser}
          searchDefault="teacher-guide"
          backUrl="/teacher-guide"
        />
        <AppBody>
          <TopicList
            topics={topics}
            subtopics={subtopics}
            urlBase="/teacher-guide"
          />
        </AppBody>
        <AppMenu pathname={location.pathname} authUser={authUser} />
      </Wrap>
    </>
  )
}

export const query = graphql`
  query {
    contentfulCourse(slug: { eq: "teacher-guide" }) {
      topics {
        title
        slug
      }
      subtopics {
        title
        id
        slug
        releaseTiming
        publishDate
        code
        isActive
        topic {
          id
          slug
          title
        }
      }
    }
  }
`

const condition = authUser => !!authUser

const TeacherGuide = compose(withAuthorization(condition))(TeacherGuideBase)

export default TeacherGuide
