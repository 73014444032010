import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { getContentReleaseBlock } from '../../utils/helpers'
import { totalCourseReleases } from '../../utils/siteConfig'
import theme from '../../styles/theme'
import { ADMIN_COURSES_CODE, SUBTOPIC_STATUS } from '../../constants/global'

export default class TopicListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
    }
    this._handleToggle = this._handleToggle.bind(this)
  }

  _renderSubItems(subItems) {
    const { authUser, urlBase } = this.props
    const currentRelease = getContentReleaseBlock(
      authUser.intake.startDate,
      authUser.intake.endDate,
      totalCourseReleases,
      authUser
    )

    return subItems.map(function(subtopic, i) {
      const key = `subtopic-${i}`
      const { title, slug, code, releaseTiming } = subtopic

      let isActive = false
      if (releaseTiming) {
        if (currentRelease >= releaseTiming) {
          isActive = true
        }
      }

      // TODO: Tmp hack for admin user visibility
      if (
        authUser.email === 'admin@theguide.design' ||
        authUser.email === 'accounts@standingby.co' ||
        authUser.roles.admin ||
        authUser.roles.teacher
      ) {
        isActive = true
      } else if (authUser.roles.graduate && authUser.roles.graduate === true) {
        isActive = true
      } else if (
        urlBase === '/career-guide' &&
        authUser.roles.graduate !== true
      ) {
        isActive = false
      }

      const codeContent = urlBase === '/career-guide' ? null : <></>

      // Existing courses + New york (eg "NYC Sept FT 2023" OR "ONLUK FEB 2023")
      // Show subtopics:
      // Current pages only
      // To be archived

      // New courses ("SEP 2023") + Shilumni (old start date)
      // Show subtopics:
      // Current pages (that aren't to be archived) and NEW pages

      /*
      status
      [] Current
      [] To be archived
      [] New
      */

      // Existing courses (eg "NYC Sept FT 2023" OR "ONLUK FEB 2023")
      // Show subtopics:
      // -- Current
      // -- To be archived

      // New courses ("SEP 2023") OR graduate
      // Show subtopics:
      // -- Current (that aren't to be archived)
      // -- NEW

      /*
      status
      [] Current
      [] To be archived
      [] New
      */

      const userCourseCode = authUser.intake.code
      const isGraduated = authUser.roles.graduate

      const { new: newCourses } = ADMIN_COURSES_CODE
      const { old: oldCourses } = ADMIN_COURSES_CODE
      const isNewCourse = newCourses.includes(userCourseCode)
      const isOldCourse = oldCourses.includes(userCourseCode)
      const isSubtopicStatus = val => subtopic.status === val

      if (
        (isGraduated || isNewCourse) &&
        isSubtopicStatus(SUBTOPIC_STATUS.TO_BE_ARCHIVED)
      ) {
        return null
      } else if (
        !isGraduated &&
        isOldCourse &&
        isSubtopicStatus(SUBTOPIC_STATUS.NEW)
      ) {
        return null
      }

      const url = `${urlBase}/${slug}`
      if (isActive) {
        return (
          <SubTopic key={key} active={true}>
            <Link to={url}>
              {title} {codeContent}
            </Link>
          </SubTopic>
        )
      } else {
        return (
          <SubTopic key={key} active={false}>
            <span>
              {title} {codeContent}
            </span>
          </SubTopic>
        )
      }
    })
  }

  _handleToggle() {
    this.setState({
      isActive: !this.state.isActive,
    })
  }

  render() {
    const { title, subItems, urlBase } = this.props
    const { isActive } = this.state

    let highlight
    switch (urlBase) {
      case '/graphic-design':
        highlight = theme.colors.highlight1
        break
      case '/career-guide':
        highlight = theme.colors.highlight2
        break
      case '/teacher-guide':
        highlight = theme.colors.highlight3
        break
      default:
        highlight = theme.colors.highlight1
    }

    const subItemsElements = this._renderSubItems(subItems).filter(el => !!el)

    let subContent
    if (isActive) {
      subContent = <SubItems>{subItemsElements}</SubItems>
    }

    return (
      <Topic>
        <Title
          onClick={this._handleToggle}
          isActive={isActive}
          highlight={highlight}
        >
          {title}
          <Count>{subItemsElements.length}</Count>
        </Title>
        {subContent}
      </Topic>
    )
  }
}

const Topic = styled.div`
  margin-bottom: 1em;
  position: relative;
  transition: all 0.6s ease-out;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    margin-bottom: 0.3em;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    margin-bottom: 0.2em;
  }
`

const SubTopic = styled.div`
  border-bottom: 2px solid ${props => props.theme.colors.greyDark2};
  width: calc(50% - 3px);
  &:first-child {
    border-top: 2px solid ${props => props.theme.colors.greyDark2};
  }
  &:nth-child(2) {
    border-top: 2px solid ${props => props.theme.colors.greyDark2};
  }
  &:nth-of-type(odd) {
    margin-right: 0px;
  }
  a,
  span {
    color: ${props =>
      props.active ? props.theme.colors.white : props.theme.colors.greyDark2};
    display: block;
    padding: 15px 0;
    line-height: 130%;
    margin-bottom: 2px;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    a,
    span {
      padding: 12px 0;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 100%;
    &:nth-of-type(odd) {
      margin-right: 0px;
    }
    &:nth-child(2) {
      border-top: none;
    }
    a,
    span {
      padding: 10px 0;
    }
  }
`

const Title = styled.div`
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: ${props =>
    props.isActive ? props.highlight : props.theme.colors.white};

  line-height: 80%;
  &:hover {
    opacity: 0.8;
  }

  ${props => props.theme.fontSizing(props.theme.font.titleSizes)};

  /* HACK for fine control :) */
  @media screen and (max-width: 1400px) {
    font-size: 68px;
    line-height: 68px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 58px;
    line-height: 58px;
  }

  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-right: 5%;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    /* HACK because lazy :) */
    font-size: 32px;
    line-height: 34px;
  }
`

const Count = styled.span`
  position: relative;
  top: -55px;
  ${props => props.theme.fontSizing(props.theme.font.navMiniSizes)};
  font-weight: normal;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    top: -50px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    top: -20px;
    left: 3px;
  }
`

const SubItems = styled.div`
  padding: 30px 0 50px 0;
  width: 66.66%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 1800px) {
    width: 100%;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding: 25px 0 30px 0;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding: 14px 0 20px 0;
    width: 100%;
  }
`

const Code = styled.em`
  color: ${props => props.theme.colors.greyDark2};
  text-transform: uppercase;
  margin-left: 5px;
  font-style: normal;
`
