export const ADMIN_COURSES_CODE = {
  old: [
    'NY PTWT FEB 23',
    'NY PTWT FEB',
    'ONLAU FEB 2023',
    'ONLWC FEB 2023',
    'ONLEC FEB 2023',
    'ONLUK FEB 2023',
    'LDN PTWT FEB 23',
    'SYD PTMT FEB 2023',
    'NYC Sept FT 2023',
  ],
  new: ['PTONL Sept 2023', 'FTONL SEP23'],
}

export const SUBTOPIC_STATUS = {
  CURRENT: 'Current',
  TO_BE_ARCHIVED: 'To be archived',
  NEW: 'New',
}
